import React from "react";
import {
  Card,
  CardContent,
  IconButton,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import {Link as ReactLink} from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import {useMutation} from "@apollo/client";
import {DELETE_LOG, GET_LOGS} from "./gqlHelper.ts";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import {TraceTimelineItem} from "src/pages/Snaphots/@types.ts";
import {Avatar} from "@components/Avatar.tsx";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

type Props = {
  trace: TraceTimelineItem;
};

export const EvenLogItem: React.FC<Props> = ({trace}) => {
  const [deleteLog] = useMutation(DELETE_LOG, {
    refetchQueries: [GET_LOGS],
  });

  return (
    <Card>
      <CardContent
        component={Stack}
        direction="row"
        justifyContent="space-between"
        gap={4}
      >
        <Stack
          direction="row"
          alignItems="center"
          gap={3}
          flexWrap="wrap"
          flexGrow={1}
        >
          <Typography variant="body2" color="primary.light" sx={{minWidth: 50}}>
            {trace.id}
          </Typography>

          <Stack direction={"row"} gap={2} alignItems={"center"}>
            <Avatar photoURL={trace.user?.photoURL ?? undefined} />
            <Typography variant="body2" color="textSecondary" width={200}>
              {trace.user?.name ?? "<user unknown>"}
            </Typography>
            <Link component={ReactLink} to={`/logs/` + trace.id}>
              {trace.name}
            </Link>
          </Stack>

          <Typography variant="body2" color="textSecondary" width={300}>
            {dayjs(trace.startTime).format("dddd, h:mm:ss A z")}
          </Typography>
        </Stack>

        <IconButton
          onClick={() => {
            void deleteLog({variables: {id: trace.id}});
          }}
        >
          <DeleteIcon />
        </IconButton>
      </CardContent>
    </Card>
  );
};
