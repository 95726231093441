import {FilteringOptionType, GetStacksQuery} from "src/__generated__/graphql";
import FilterBar, {ProcessForSelector} from "./goroutine-filter-bar";
import {useQuery} from "@apollo/client";
import React, {useState} from "react";
import {gql} from "src/__generated__/gql";
import {Box, ToggleButton, Tooltip} from "@mui/material";
import {LocalFireDepartment, Reorder} from "@mui/icons-material";
import Stacks from "./stacks";
import {
  GoroutinesTabMode,
  useSnapshotState,
} from "src/providers/snapshot-state";
import {Link} from "react-router-dom";
import {StyledToggleButtonGroup} from "src/components/util.tsx";
import {toastError} from "src/components/tables/util.tsx";
import {Flamegraph, ParseTreeData, TreeNode} from "@components/Flamegraph";
import {NodeSelector} from "@components/Flamegraph/FlamegraphState.ts";

const GET_TREE = gql(/* GraphQL */ `
  query GetTree($snapshotID: Int!, $filters: [StacksFilter!]) {
    getTree(snapshotID: $snapshotID, filters: $filters) {
      treeJSON
      goroutines {
        ID {
          ID
          ProcessID
        }
        Status
        WaitForNanos
      }
    }
  }
`);

export default function GoroutinesTab(props: {
  processSnapshots: ProcessForSelector[];
  // stackAndSchemas is undefined while the data is loading.
  stacksAndSchemas?: GetStacksQuery["getStacks"];
  setSelectedFrame: NodeSelector;
}): React.JSX.Element {
  const {processSnapshots, setSelectedFrame} = props;
  const snapshotState = useSnapshotState();
  const {
    state: {filters, goroutinesMode: mode, snapshotID},
  } = snapshotState;

  const [flamegraphHighlightFilter, setFlamegraphHighlightFilter] =
    useState<string>("");

  // Construct the filters used to load the flamegraph data. The filters
  // handled by the flamegraph internally are ignored (i.e. filters that the
  // flamegraph reflects through zooming, namely stack prefix and binary). Not
  // reloading the flamegraph when these filters change, and instead letting its
  // zooming mechanism reflect these selections, results in smoother animations.
  const filtersExceptZoom = filters.filter(
    (f) =>
      f.Type != FilteringOptionType.StackPrefix &&
      f.Type != FilteringOptionType.BinaryId,
  );

  const {
    data,
    loading: loadingFlamegraph,
    error,
  } = useQuery(GET_TREE, {
    variables: {
      snapshotID,
      filters: filtersExceptZoom,
    },
  });
  let flamegraphData: TreeNode | undefined;
  let flamegraphError: string | undefined;
  if (error) {
    flamegraphError = error.message;
    toastError(error);
  } else if (data) {
    // TODO: Parsing the data might be expensive. We shouldn't do it on every
    // render; we should find a way to memoize it or do it only after a new
    // query has actually been run.
    flamegraphData = ParseTreeData(data.getTree.treeJSON);
  }

  if (mode == GoroutinesTabMode.Flamegraph && flamegraphError) {
    return <>Error loading stacks data: {flamegraphError}</>;
  }

  if (
    (mode == GoroutinesTabMode.Flamegraph && loadingFlamegraph) ||
    (mode == GoroutinesTabMode.Stacks && !props.stacksAndSchemas)
  ) {
    return <>Loading</>;
  }

  return (
    <>
      <Box
        display={"flex"}
        flexDirection={"row"}
        sx={{width: "100%", my: 2}}
        alignItems={"stretch"}
        minHeight={"41px"}
      >
        <StyledToggleButtonGroup value={mode} exclusive size="small">
          <Tooltip title="Switch to Flamegraph">
            <span>
              <ToggleButton
                value="flamegraph"
                key={GoroutinesTabMode.Flamegraph}
                component={Link}
                to={snapshotState.setGoroutinesModeURL(
                  GoroutinesTabMode.Flamegraph,
                )}
                selected={mode == GoroutinesTabMode.Flamegraph}
              >
                <LocalFireDepartment />
              </ToggleButton>
            </span>
          </Tooltip>
          <Tooltip title="Switch to viewing stack traces">
            <span>
              <ToggleButton
                value="stacks"
                key={GoroutinesTabMode.Stacks}
                component={Link}
                to={snapshotState.setGoroutinesModeURL(
                  GoroutinesTabMode.Stacks,
                )}
                selected={mode == GoroutinesTabMode.Stacks}
              >
                <Reorder />
              </ToggleButton>
            </span>
          </Tooltip>
        </StyledToggleButtonGroup>
        <FilterBar
          snapshots={processSnapshots}
          onInputChange={(value) => setFlamegraphHighlightFilter(value)}
        />
      </Box>
      {mode === GoroutinesTabMode.Flamegraph && (
        <Flamegraph
          root={flamegraphData!}
          highlighterFilter={flamegraphHighlightFilter}
          setSelectedFrame={setSelectedFrame}
        />
      )}
      {mode === GoroutinesTabMode.Stacks && (
        <Stacks
          snapshot={props.stacksAndSchemas!.GoroutineGroups}
          tableSchemas={props.stacksAndSchemas!.TableSchemas}
        />
      )}
    </>
  );
}
