import {useSearchParams} from "react-router-dom";
import React, {useState} from "react";
import {useQuery} from "@apollo/client";
import {Box, Stack, Typography} from "@mui/material";
import dayjs, {Dayjs} from "dayjs";
import {computeSearchParams, stateFromURL} from "src/util/url.ts";
import {
  GET_LOGS,
  GET_SNAPSHOTS,
  makeSnapshotsParamUpdaters,
} from "./gqlHelper.ts";
import SelectorEnvironment from "../../components/SelectorEnvironment.tsx";
import {Timeline} from "../../components/Timeline.tsx";
import {SnapshotItem} from "./SnapshotItem.tsx";
import {SelectorDate} from "../../components/SelectorDate.tsx";
import {SelectorProgram} from "../../components/SelectorProgram.tsx";
import {TimelineItem} from "./@types.ts";
import {EvenLogItem} from "src/pages/Snaphots/EvenLogItem.tsx";
import {noEnvSymbol} from "src/pages/Agents/components/AllProcesses/AllProcesses.tsx";

export default function Snapshots() {
  const [searchParams, setSearchParams] = useSearchParams();
  const paramUpdater = makeSnapshotsParamUpdaters();

  const urlState = stateFromURL(searchParams, paramUpdater);
  const envFilter = urlState.env; // null means no selection

  const [dateFilter, setDateFilter] = useState<Dayjs | undefined>(undefined);
  const [programFilter, setProgramFilter] = useState<string | undefined>(
    undefined,
  );

  // Turn the value in the graphql format (i.e. "" for "no environment").
  const noEnvValue = "<no environment>";
  const canonicalEnv = envFilter != noEnvValue ? envFilter : "";

  // undefined means remove the filtering.
  const setEnvironmentFilter = (
    newEnv: string | typeof noEnvSymbol | undefined,
  ) => {
    setSearchParams(
      computeSearchParams(
        searchParams,
        {
          env:
            newEnv == null
              ? undefined
              : newEnv == noEnvSymbol
                ? noEnvValue
                : newEnv,
        },
        paramUpdater,
      ),
    );
  };

  // NOTE: I didn't use useSuspenseQuery here because it doesn't seem to play
  // nicely with the DatePicker. With useSuspenseQuery, sometimes the DatePicker
  // doesn't close after selecting a date.
  const {
    data: snapshotsRes,
    loading: loadingSnapshots,
    error: snapshotsErr,
  } = useQuery(GET_SNAPSHOTS, {
    fetchPolicy: "cache-and-network",
    variables: {
      input: {
        Environment: canonicalEnv,
        Program: programFilter,
        Date: dateFilter?.format("YYYY-MM-DD"),
      },
    },
  });
  const {
    data: tracesRes,
    loading: loadingTraces,
    error: tracesErr,
  } = useQuery(GET_LOGS, {
    fetchPolicy: "cache-and-network",
    variables: {
      input: {
        Environment: canonicalEnv,
        Program: programFilter,
        Date: dateFilter?.format("YYYY-MM-DD"),
      },
    },
  });

  if (snapshotsErr) {
    throw snapshotsErr;
  }
  if (tracesErr) {
    throw tracesErr;
  }

  if (loadingSnapshots || loadingTraces) {
    return <div>Loading...</div>;
  }

  const snapshots = snapshotsRes!.getSnapshots;
  const logs = tracesRes!.getLogs;
  const items: TimelineItem[] = [
    ...logs.map(
      (t) =>
        ({
          type: "trace",
          id: t.id,
          timestamp: dayjs(t.startTime),
          environment: t.environment,
          startTime: t.startTime,
          name: "Event log" + (t.environment ? `: ${t.environment}` : ""),
          user: t.user ?? undefined,
        }) as const,
    ),
    ...snapshots.map(
      (s) =>
        ({
          type: "snapshot",
          timestamp: dayjs(s.captureTime),
          ...s,
        }) as const,
    ),
  ];

  return (
    <>
      <Box sx={{my: 3}}>
        <Typography variant="h1">Snapshots</Typography>
      </Box>

      <Stack direction="row" gap={2} mb={5} flexWrap="wrap">
        <SelectorEnvironment
          allowEmpty={true}
          selectedEnv={envFilter}
          onChange={setEnvironmentFilter}
        />

        <SelectorProgram state={programFilter} setState={setProgramFilter} />

        {/*TODO: Disable dates without snapshots. The difficulty is that, on*/}
        {/*year change, the calendar calls this function for every day in the*/}
        {/*year?*/}
        {/*shouldDisableDate={(newDate) => true}*/}
        <SelectorDate state={dateFilter} setState={setDateFilter} />
      </Stack>

      {items.length > 0 ? (
        <Timeline
          data={items}
          sortDate="timestamp"
          renderItem={(item: TimelineItem) =>
            item.type == "snapshot" ? (
              <SnapshotItem snapshot={item} />
            ) : (
              <EvenLogItem trace={item} />
            )
          }
        />
      ) : (
        <Typography>
          {envFilter != undefined ||
          programFilter != undefined ||
          dateFilter != undefined ? (
            <>No snapshots found.</>
          ) : (
            <>
              No snapshots were captured. Check out{" "}
              <a href="https://docs.side-eye.io" target={"_blank"}>
                the docs
              </a>{" "}
              to get started.{" "}
            </>
          )}
        </Typography>
      )}
    </>
  );
}
