import React, {createContext, PropsWithChildren, useContext} from "react";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import CaptureSnapshotButton from "../../../components/capture-snapshot-button.tsx";
import {styled} from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import {AppBarProps as MuiAppBarProps} from "@mui/material/AppBar/AppBar";
import {useNavigate} from "react-router-dom";
import {AppConfigContext} from "../../../providers/app-config-provider.tsx";
import {ButtonDocs} from "../../../components/ButtonDocs.tsx";
import {drawerWidth} from "./Sidebar.tsx";
import IconSideEye from "../../../components/icons/IconSideEye.tsx";
import {Avatar} from "../../../components/Avatar.tsx";
import {Divider} from "@mui/material";
import {ThemeSelectionToggle} from "@components/ThemeSelectionToggle.tsx";

export const headerHeight = 80;

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({theme, open}) => ({
  zIndex: 2,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  backgroundColor: "transparent",
  backgroundImage: "none",
  height: `${headerHeight}px`,
  justifyContent: "space-evenly",
  paddingInline: "0",
}));

export type HeaderProps = {
  open: boolean;
};

// SnapshotOpenerConfig stores a click handler for opening a new snapshot. It is
// meant to be used through useContext(SnapshotOpenerContext) for installing a
// custom handler.
class SnapshotOpenerConfig {
  handler?: (
    event: React.MouseEvent<HTMLButtonElement>,
    snapshotID: number,
  ) => Promise<void>;

  // setOpenSnapshotHandler installs a new handler. Returns a cleanup function
  // which restores the old handler.
  setOpenSnapshotHandler(
    handler: (
      event: React.MouseEvent<HTMLButtonElement>,
      snapshotID: number,
    ) => Promise<void>,
  ): () => void {
    const oldHandler = this.handler;
    this.handler = handler;
    return () => {
      this.handler = oldHandler;
    };
  }
}

export const SnapshotOpenerContext = createContext<SnapshotOpenerConfig>(
  new SnapshotOpenerConfig(),
);

export const SnapshotOpenerConfigProvider: React.FunctionComponent<
  PropsWithChildren<unknown>
> = ({children}) => {
  return (
    <SnapshotOpenerContext.Provider value={new SnapshotOpenerConfig()}>
      {children}
    </SnapshotOpenerContext.Provider>
  );
};

export const Header: React.FC<HeaderProps> = ({open}) => {
  const navigate = useNavigate();
  const appCfg = useContext(AppConfigContext);
  const ephemeralEnv = appCfg.EphemeralEnvironment;
  const user = appCfg.User;
  const loginScreen = appCfg.AuthEnabled && !user;

  return (
    <AppBar position="fixed" open={user ? open : false}>
      <Toolbar sx={{height: "100%", py: 2.5}} variant="dense">
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            gap: 4,
            alignItems: "stretch",
            justifyContent: "flex-end",
            height: "100%",
          }}
        >
          {loginScreen && (
            <Box sx={{marginRight: "auto"}}>
              <IconSideEye />
            </Box>
          )}

          {!loginScreen && <CaptureSnapshotButton />}
          <ThemeSelectionToggle />

          <Box component="div" sx={{display: "flex", alignItems: "center"}}>
            <ButtonDocs />

            {!ephemeralEnv && user && (
              <>
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{marginInline: "24px"}}
                />
                <Box>
                  <Avatar
                    photoURL={user.photoURL ?? undefined}
                    onClick={() => navigate("/login")}
                  />
                </Box>
              </>
            )}
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
