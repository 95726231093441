import React from "react";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import {GetLogMetaQuery} from "@graphql/graphql.ts";
import LatencyHistogram from "src/pages/LiveEventLog/LatencyHistogram.tsx";

// HistogramPicker renders a dropdown for selecting the name of a function in a
// log, and renders the histogram for that function's duration by using
// <LatencyHistogram>.
export default function HistogramPicker({
  log,
  poll,
}: {
  log: GetLogMetaQuery["getLog"];
  poll: boolean;
}): React.JSX.Element {
  const [selectedFunc, setSelectedFunc] = React.useState<string>(
    log.specs![0].funcQualifiedName,
  );

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
      }}
    >
      <Stack
        direction={"column"}
        spacing={1}
        sx={{width: "100%", height: "100%"}}
      >
        <Box>
          <FormControl sx={{mt: 1}}>
            <InputLabel>Function</InputLabel>
            <Select
              value={selectedFunc}
              label="Function"
              onChange={(e) => setSelectedFunc(e.target.value)}
            >
              {log.specs!.map((spec) => (
                <MenuItem
                  key={spec.funcQualifiedName}
                  value={spec.funcQualifiedName}
                >
                  {spec.funcQualifiedName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <LatencyHistogram
          logID={log.id}
          funcQualifiedName={selectedFunc}
          numBuckets={30}
          poll={poll}
        />
      </Stack>
    </Box>
  );
}
