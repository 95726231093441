import {useParams} from "react-router-dom";
import {useSuspenseQuery} from "@apollo/client";
import React, {Fragment} from "react";
import {
  Box,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import {renderPossiblyJSONValue} from "src/components/cell.tsx";
import {GET_LOG, GET_LOG_EVENTS} from "./gql";
import {
  formatDurationNanos,
  NANOS_PER_MILLI_BIGINT,
  ProcessInfo,
} from "@util/util.ts";
import EventFrequency from "src/pages/LiveEventLog/EventFrequency.tsx";
import HistogramPicker from "@components/HistogramPicker/HistogramPicker.tsx";
import {DownloadDatabaseButton} from "@components/DownloadDatabaseButton.tsx";
import GoroutineLink from "@components/goroutine-link.tsx";
import {ProcessResolver} from "@util/process-resolver.ts";
import {ProcessResolverProvider} from "@providers/processResolverProvider";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {Tables} from "@components/tables/tables.tsx";

export default function EventLogViewer(): React.JSX.Element {
  const pathParams = useParams();
  const logID = parseInt(pathParams.logID!);
  const [tab, setTab] = React.useState("log");

  const {data: logRes} = useSuspenseQuery(GET_LOG, {
    variables: {id: logID},
  });
  const log = logRes.getLog;

  const {data: eventsRes} = useSuspenseQuery(GET_LOG_EVENTS, {
    variables: {logID},
  });
  const events = eventsRes.getLogEvents;

  if (log.specs?.length == 0) {
    return <>Error: specs missing for log</>;
  }

  const processResolver = new ProcessResolver(
    log.processes.map(
      (s): ProcessInfo => ({
        ProcessID: s.processID,
        BinaryID: s.binaryID,
        FriendlyName: s.processFriendlyName,
        CaptureTimeNanos:
          BigInt(Date.parse(s.captureTime)) * NANOS_PER_MILLI_BIGINT,
      }),
    ),
    [] /* binaries */,
  );

  return (
    <div>
      <TabContext value={tab}>
        <Stack
          direction={"row"}
          justifyContent="space-between"
          sx={{width: "100%"}}
        >
          <TabList variant="standard" sx={{width: "100%"}}>
            <Tab value={"log"} label="Log view" onClick={() => setTab("log")} />
            <Tab
              value={"tables"}
              label="Data tables"
              onClick={() => setTab("tables")}
            />
          </TabList>
          <span></span>
          <DownloadDatabaseButton
            logID={logID}
            fileName={`log-${logID}.db`}
            tooltip={"Download a SQLite database with this log's data"}
          />
        </Stack>

        <ProcessResolverProvider value={processResolver}>
          <TabPanel value={"log"}>
            <>
              <Box sx={{width: "100%", height: "400px"}}>
                <Stack
                  direction={"row"}
                  spacing={2}
                  sx={{width: "100%", height: "400px"}}
                >
                  <Box sx={{width: "50%", height: "100%"}}>
                    <EventFrequency logID={logID} poll={false} />
                  </Box>
                  <Box sx={{width: "50%", height: "100%"}}>
                    <HistogramPicker log={log} poll={false} />
                  </Box>
                </Stack>
              </Box>
            </>

            <Table
              style={{
                marginTop: "10px",
                width: "100%",
                tableLayout: "fixed",
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell style={{width: "150px"}}>GoroutineID</TableCell>
                  <TableCell style={{width: "250px"}}>Timestamp</TableCell>
                  <TableCell style={{width: "300px"}}>Duration</TableCell>
                  <TableCell style={{width: "100%"}}>Message</TableCell>
                  <TableCell style={{width: "100%"}}>Data</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {events.map((event, i) => (
                  <Fragment key={`${i}-header`}>
                    <TableRow>
                      <TableCell>
                        <GoroutineLink
                          processID={event.goroutineID.ProcessID}
                          goroutineID={event.goroutineID.ID}
                          // TODO: figure out a URL for this link
                          clickURL={undefined}
                        />
                      </TableCell>
                      <TableCell>{event.timestamp}</TableCell>
                      <TableCell>
                        {event.funcDurationNanos
                          ? formatDurationNanos(event.funcDurationNanos)
                          : ""}
                      </TableCell>
                      <TableCell>{event.message}</TableCell>
                      <TableCell>
                        {renderPossiblyJSONValue(
                          event.data,
                          undefined /* onExpand */,
                          0 /* defaultExpandedLevels */,
                        )}
                      </TableCell>
                    </TableRow>
                  </Fragment>
                ))}
              </TableBody>
            </Table>
          </TabPanel>

          <TabPanel value={"tables"}>
            <Tables logID={logID} />
          </TabPanel>
        </ProcessResolverProvider>
      </TabContext>
    </div>
  );
}
